@font-face{
  font-family: DisposableDroidBB_bld;
  src: url(./fonts/DisposableDroidBB_bld.otf);
}

@font-face{
  font-family: DisposableDroidBB;
  src: url(./fonts/DisposableDroidBB.otf);
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body {
    font-size: 8px;
  }

  .backBtn {
    width: 150px;
    height: 40px;
    background-color: #4C6FFF;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .credits {
    min-height: 200px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  body {
    font-size: 9px;
  }
  .backBtn {
    width: 150px;
    height: 40px;
    background-color: #4C6FFF;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .credits {
    min-height: 300px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
  .backBtn {
    width: 300px;
    height: 80px;
    max-width: 300px;
    background-color: #4C6FFF;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .credits {
    min-height: 500px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  body {
    font-size: 18px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  body {
    font-size: 18px;
  }
}

a {
  text-decoration: none;
}

.petitionBtn{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  text-align: center;
  min-height: 100%;
  min-width: 100%;
  background-color: blue;
  padding-bottom: 200px;
  height: 100%;
}

html, body, .App, .App>div {
  height: 100%;
  background-color:  #66CF7C;
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}

.img-frame {
  /*position: relative;*/
  width: 66.5%;
  max-width: 1700px
}

.clear-logo {
  width: 45%;
  max-width: 1700px;
  margin-top: 5%;
  margin-bottom: 5%;
}

.mobile-frame {
  width: 100%;
  height: 100%;
  min-height: 480px;
}

.mobile-frame iframe {
  width: 100%;
  height: 100%;
}

.full-wrapper {
  background-color: red;
}

.aspect-ratio {
  position: relative;
  max-width: 100%;
  /*height: 0;*/
  /*padding-bottom: 56.25%;*/
  margin: 0 auto;
  height: 85vh;
}

.aspect-ratio-desktop {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin: 0 auto;
}

.aspect-ratio-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.aspect-ratio iframe {
  width: 100%;
}

p {
  display: flex;
  margin-left: 10%;
  font-size: 1em;
  margin-top: 3px;
  margin-bottom: 3px;
}

.credits {
  margin-bottom : 5em;
  display : flex;
  flex-direction: column;
  justify-content : flex-end;
  width : 100%;
  height: 100%;
  flex: 1;
  color: #2627FF;
  font-size: 1.5em;
  font-family: 'DisposableDroidBB_bld';
}

.backBtn:hover {
  cursor: pointer;
}

.bodyStyle {
  /*"background-color": " #66CF7C",*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btnWrapper {
  /*background-color: blue;*/
  margin-top : 4em;
  margin-bottom : 4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;  width: 80%;
}

.backBtn p {
  color: white;
  font-size: 2em;
  font-family: 'DisposableDroidBB_bld';
  margin: 0 auto;
  font-weight: bold;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2627FF;
  font-size: 1.7em;
  padding: 1em;
  font-family: 'DisposableDroidBB_bld';
}

.infoLink {
  
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #2627FF;
  font-size: 4em;
  padding: 1em;
  font-family: 'DisposableDroidBB_bld';
}

.infoLinkWrapper {
  width: 100%;
  margin-top : 4em;
  margin-bottom : 4em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.credits p {
  text-align: left;
}
